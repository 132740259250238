<template>
    <div>
        <v-row no-gutters dense class="my-card d-flex flex-column">
            <div class="pa-0">
                <v-row no-gutters dense class="d-flex flex-grow-0">
                    <v-col cols="5" class="pa-0">
                        <p class="my-heading">Distributors</p>
                    </v-col>
                    <v-col cols="6" class="pa-0 filter-search">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            color="#CACEDA"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="export-icon">
                        <div
                            v-on:click="getdistributorList()"
                            style="cursor: pointer"
                        >
                            <v-tooltip left color="#757575">
                                <template v-slot:activator="{ on }">
                                    <img
                                        v-on="on"
                                        :src="
                                            $store.state.icons.icons[
                                                'file-export'
                                            ]
                                        "
                                        style="
                                            height: 24px;
                                            filter: invert(61%) sepia(10%)
                                                saturate(1008%)
                                                hue-rotate(178deg)
                                                brightness(84%) contrast(84%);
                                        "
                                        alt
                                    />
                                </template>
                                <span>Export distributor detail</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-data-table
                :items="distributorList"
                :page.sync="page"
                :headers="header"
                :items-per-page="itemsPerPage"
                :search="search"
                hide-default-footer
                mobile-breakpoint="1080"
                no-data-text="No record found"
            >
                <template v-slot:item.bid_count="{ item }">
                    <div v-if="item.bid_count != '0'">
                        <v-tooltip left color="#757575">
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    class="my-pointer"
                                    v-on:click="showBids(item.company_id)"
                                >
                                    {{ item.bid_count }} /
                                    {{ item.winning_bid_count }}
                                </div>
                            </template>
                            <span>Get all bids of distributor</span>
                        </v-tooltip>
                    </div>
                    <div v-else>
                        {{ item.bid_count }} / {{ item.winning_bid_count }}
                    </div>
                </template>
                <template v-slot:item.delivery_count="{ item }">
                    <div v-if="item.delivery_count != '0'">
                        <v-tooltip left color="#757575">
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    class="my-pointer"
                                    v-on:click="showDeliveries(item.company_id)"
                                >
                                    {{ item.delivery_count }}
                                </div>
                            </template>
                            <span>Get all deliveries of distributor</span>
                        </v-tooltip>
                    </div>
                    <div v-else>{{ item.delivery_count }}</div>
                </template>
                <template v-slot:item.total_revenue="{ item }">
                    <div>
                        {{ $options.filters.currency(item.total_revenue) }}
                    </div>
                </template>
                <template v-slot:item.total_mmb_fee="{ item }">
                    <div>
                        {{ $options.filters.currency(item.total_mmb_fee) }}
                    </div>
                </template>
                <template v-slot:item.last_login="{ item }">
                    <div>
                        {{
                            item.last_login ? formatDate(item.last_login) : "-"
                        }}
                    </div>
                </template>
                <template v-slot:item.status="{ item }">
                    <div
                        v-if="item.status === 'A'"
                        class="
                            text-tag-green text-tag-btn
                            d-flex
                            align-center
                            justify-center
                            my-2
                        "
                    >
                        Active
                    </div>
                    <div
                        v-else-if="item.status === 'I'"
                        class="
                            text-tag-blue text-tag-btn
                            d-flex
                            align-center
                            justify-center
                            my-2
                        "
                    >
                        Inactive
                    </div>
                    <div
                        v-else-if="item.status === 'D'"
                        class="
                            text-tag-red text-tag-btn
                            d-flex
                            align-center
                            justify-center
                            my-2
                        "
                    >
                        Deleted
                    </div>
                    <div
                        v-else-if="item.status === 'S'"
                        class="
                            text-tag-blue text-tag-btn
                            d-flex
                            align-center
                            justify-center
                            my-2
                        "
                    >
                        Suspend
                    </div>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip left color="#757575">
                        <template v-slot:activator="{ on }">
                            <div
                                v-on="on"
                                class="my-pointer"
                                v-on:click="userEdit(item)"
                            >
                                <img
                                    :src="$store.state.icons.icons['user-edit']"
                                    class="table-icon"
                                />
                            </div>
                        </template>
                        <span>Distributor profile</span>
                    </v-tooltip>
                </template>
            </v-data-table>

            <div
                class="d-flex align-center justify-center my-tabel-footer py-2"
            >
                <v-pagination
                    v-model="page"
                    color="#2974FF"
                    :total-visible="7"
                    :length="
                        getTotalPage(
                            this.$store.state.distributorlist.distributor_list
                        )
                    "
                ></v-pagination>
            </div>
        </v-row>
        <app-user-edit ref="useredit"></app-user-edit>
    </div>
</template>

<script>
import { ExportToCsv } from "export-to-csv";
import moment from "moment-timezone";

const UserEdit = () => import("../common/UserEdit");
export default {
    components: {
        AppUserEdit: UserEdit,
    },

    data() {
        return {
            page: 1,
            itemsPerPage: 10,
            search: "",
            distributor_data: [],
            header: [
                { text: "Fullname", value: "fullname" },
                { text: "Email", value: "email" },
                { text: "Phone", value: "phone" },
                { text: "Bids/Won", value: "bid_count" },
                { text: "Deliveries", value: "delivery_count" },
                { text: "Total Revenue", value: "total_revenue" },
                { text: "Total MMB Fee", value: "total_mmb_fee" },
                { text: "Status", value: "status" },
                { text: "Last Login", value: "last_login" },
                {
                    text: "Action",
                    value: "action",
                    sortable: false,
                    align: "center",
                },
            ],
        };
    },

    mounted() {
        let data = this.$store.getters.getDistributor;
        if (data.length === 0) {
            this.$store.dispatch("distList");
        }
    },

    computed: {
        distributorList() {
            return this.$store.getters.getDistributor;
        },
    },

    methods: {
        formatDate(data) {
            return moment(data).format("M-D-Y hh:mm A");
        },

        getTotalPage(data) {
            return Math.ceil(data.length / this.itemsPerPage);
        },

        userEdit(item) {
            this.$refs.useredit.openUserEdit(item.id);
        },

        userDelete(item) {
            item.status = "D";
            this.$store.dispatch("distributorDelete", item);
            let alert = {
                show: true,
                message: item.fullname + " is deleted",
                type: "success",
            };
            this.$store.commit("showAlert", alert);
        },

        userSuspend(item) {
            item.status = "S";
            this.$store.dispatch("distributorSuspend", item);
            let alert = {
                show: true,
                message: item.fullname + " is suspended",
                type: "success",
            };
            this.$store.commit("showAlert", alert);
        },

        showBids(id) {
            this.$store.dispatch("companyBids", id);
            this.$router.push({ path: "/distributors/bids/" + id });
        },

        showDeliveries(id) {
            this.$store.dispatch("deliveryOfDist", id);
            this.$router.push({ path: "/distributors/deliveries/" + id });
        },

        getdistributorList() {
            this.distributor_data = this.$store.getters.getDistributor;
            const options = {
                filename: "Distributor List",
                fieldSeparator: ",",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Distributor List",
                useTextFile: false,
                useBom: true,
                // useKeysAsHeaders: true,
                headers: [
                    "Fullname",
                    "Email",
                    "Phone",
                    "Bids/Won",
                    "Deliveries",
                    "Total_Revenue",
                    "Total_MMB_Fee",
                    "Status",
                    "Last Login",
                ],
            };

            const csvExporter = new ExportToCsv(options);

            let distributor_ob = [];
            this.distributor_data.forEach((data) => {
                let distributor = {};
                distributor.fullname = data.fullname;
                distributor.email = data.email;
                distributor.phone = data.phone;
                distributor.bid_count = data.bid_count;
                distributor.delivery_count = data.delivery_count;
                distributor.total_revenue = data.total_revenue;
                distributor.total_mmb_fee = data.total_mmb_fee;
                distributor.last_login = data.last_login;
                if (data.status === "A") {
                    distributor.status = "Active";
                } else if (data.status === "I") {
                    distributor.status = "Inactive";
                } else if (data.status === "D") {
                    distributor.status = "Deleted";
                } else if (data.status === "S") {
                    distributor.status = "Suspended";
                }
                distributor_ob.push(distributor);
            });
            csvExporter;
            csvExporter.generateCsv(distributor_ob);
        },
    },
};
</script>
